// NAVS

@use "sass:math";

.nav-item {
  .nav-link {
    font-weight: normal;
  }
}

.nav-link {
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease, color 0.3s ease;
  cursor: pointer;

  .nav-link-icon {
    color: $primary;
    font-size: 1rem;
    width: 30px;
    text-align: center;
    opacity: .45;
    margin-left: -10px;
  }

  &:hover {
    color: $gray-700;

    .nav-link-icon {
      opacity: .9;
      color: $primary;
    }
  }

  &:disabled,
  &.disabled {
    .nav-link-icon {
      opacity: .3;
    }
  }
}

.nav-item {
  &.nav-item-header {
    text-transform: uppercase;
    font-size: math.div($font-size-base, 1.2);
    color: $gray-600;
    font-weight: bold;
    padding: $nav-link-padding-y $nav-link-padding-x;
  }

  &.nav-item-btn {
    padding: $nav-link-padding-y $nav-link-padding-x;
  }

  &.nav-item-divider {
    margin: $nav-link-padding-y 0;
    height: 1px;
    overflow: hidden;
    background: $gray-300;
  }
}

.nav {
  .badge {
    margin-left: 8px;
  }
}

.nav-pills {
  .nav-link {

    &.active,
    &.active:hover {
      color: $white;

      .nav-link-icon {
        color: $white;
        opacity: .8;
      }
    }

    &:hover {
      color: $gray-700 !important;
    }
  }
}

// Grid Menus

.grid-menu {

  [class*="col-"] {
    border-right: $border-color solid 0;
    border-bottom: $border-color solid 1px;

    &:hover {
      z-index: 5;
    }
  }

  // @include media-breakpoint-between(sm, xl) {

    [class*="col-"]:nth-last-child(-n+2) {
      border-bottom-width: 0;
    }

    [class*="col-"]:nth-child(1n) {
      border-right-width: 1px;
    }

    [class*="col-"]:nth-child(2n) {
      border-right-width: 0;
    }
  // }

  [class*="col-"]:nth-last-child(-n+1) {
    border-bottom-width: 0;
  }

  &.grid-menu-3col {

    // @include media-breakpoint-up(xl) {

      [class*="col-"]:nth-last-child(-n+3) {
        border-bottom-width: 0;
      }

      [class*="col-"]:nth-child(2n) {
        border-right-width: 1px;
      }

      [class*="col-"]:nth-child(3n) {
        border-right-width: 0;
      }
    // }
  }

  .btn {
    display: block;
    border: 0;
    min-width: 100%;
  }
}

