// Profile Boxes

@use "sass:math";

.profile-block {
  position: relative;
  overflow: hidden;

  .profile-blur {
    width: 100%;
    height: 100%;
    filter: blur(5px);
    transform: scale(1.8);
    position: absolute;
    left: -25%;
    top: -25%;
  }

  .profile-inner {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: .5;
    left: 0;
    top: 0;
  }

  .dropdown-menu-header {

    .menu-header-content {
      padding: $layout-spacer-lg;

      .menu-header-title {
        margin: math.div($layout-spacer-x, 2) 0 0;
      }

      .menu-header-subtitle {
        margin: math.div($layout-spacer-x, 3) 0 0;
      }
    }

    .menu-header-btn-pane {
      margin: math.div($layout-spacer-x, 3) 0 0;

      .btn-icon {
        color: $white;
        padding: 0;
        width: 44px;
        height: 44px;
        line-height: 37px;
        font-size: $font-size-lg;

        &:hover {
          background: rgba(255, 255, 255, .2);
        }
      }
    }
  }

}